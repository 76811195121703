<template>
  <div>
    <div v-if="loading">
      <loader size="xxs" :loader-image="false" />
    </div>
    <div v-else-if="showError">
      <ErrorPage />
    </div>
    <div v-else>
      <div v-if="ds?.children?.length === 0">
        <div class="flex flex-col gap-1">
          <div class="flex w-full justify-center">
            <Icon
              icon-name="dashboard-error"
              class="-mt-1 iconSize"
              style="width: 250px; height: 250px"
            />
          </div>
          <p class="text-xl text-center font-bold">No Data Available</p>
          <p class="text-xs text-center text-romanSilver">
            There is no data to show currently.
          </p>
        </div>
      </div>
      <org-chart v-else class="-mt-8" :datasource="ds" style="transform: rotate(180deg)">
        <template v-slot:default="{ nodeData }">
          <div class="w-full flex gap-3 org-node">
            <div class="flex">
              <img v-if="nodeData.photo" :src="nodeData.photo" alt="account" class="tw-mx-auto tw-rounded-full" style="height: 30px; width: 30px" />
              <div v-else class="w-8 h-8 rounded text-blueCrayola border text-center font-semibold pt-1">
                {{ $getInitials(nodeData.name) }}
              </div>
            </div>
            <div class="flex flex-col gap-1">
              <div class="font-semibold flex text-base text-romanSilver">
                {{ nodeData.name }}
              </div>
              <div class="text-xs text-darkPurple capitalize text-left flex flex-col gap-1">
                <p class="font-semibold">
                  {{ nodeData.title }}
                </p>
              </div>
            </div>
          </div>
          <div v-if="nodeData.users.length > 1" class="w-full flex justify-center mb-3">
            <div class="w-[48px] py-2 px-3 -bottom-2 bg-darkPurple rounded-md absolute">
              <p class="text-white text-xs font-semibold">
                +{{ nodeData.users.length }}
              </p>
            </div>
          </div>
        </template>
      </org-chart>
    </div>
  </div>
</template>

<script>
import ErrorPage from "@/modules/Admin/error403";
import OrgChart from "./OrganizationChartContainer";

export default {
  name: "ReportingLine",
  components: { OrgChart, ErrorPage },
  data() {
    return {
      ds: [],
      loading: true,
      showError: false,
    };
  },
  props: {
    userDetails: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    transformData(node) {
      if (!node) return null;
      return {
        id: node.id,
        name: `${node.fname} ${node.lname}`,
        title: `Level ${node.level?.name || "Unknown"}`,
        photo: node.photo || null,
        users: node.otherDesignationUser || [],
        children: node.reportLine ? [this.transformData(node.reportLine)] : [],
      };
    },
  },
  async mounted() {
    try {
      await this.$handlePrivilege("employeeDirectory", "viewReportingLineED");
      const response = await this.$_getEmployeeReportingLineInsights(
        this.userDetails.userId
      );
      this.ds = this.transformData(response.data.data);
    } catch (error) {
      this.showError = true;
    } finally {
      this.loading = false;
    }
  },
};
</script>

<style>
.org-node::after {
  content: "";
  position: absolute;
  top: -104px;
  left: 50.2%;
  transform: translateX(-50%);
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #ccc;
}
</style>
