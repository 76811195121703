<template>
  <div class="w-full flex flex-col my-3">
    <ErrorPage v-if="showError" />
    <div v-else>
      <div class="w-full h-full flex flex-col gap-12">
        <Card class="w-full py-3 px-4 rounded-md flex gap-5">
          <div class="w-full flex justify-between">
            <div class="flex w-full flex-grow">
              <div class="flex w-full gap-3">
                <img
                  :src="userDetails.photo"
                  class="w-32 h-44"
                  v-if="userDetails.photo"
                />
                <div
                  class="w-32 h-44 flex flex-col text-lg justify-center text-center font-semibold border rounded-md"
                  v-else
                >
                  {{
                    $getInitials(`${userDetails.fname} ${userDetails.lname}`)
                  }}
                </div>

                <div class="w-full flex flex-col gap-1">
                  <div class="w-full flex gap-8 mb-2">
                    <div class="flex items-start">
                      <p class="text-lg font-bold">
                        {{ userDetails.fname }}
                        {{ userDetails.lname }}
                      </p>
                    </div>
                  </div>

                  <div
                    class="w-full flex"
                    v-for="(option, index) in employeeOptions"
                    :key="index"
                  >
                    <div class="w-1/5 flex items-start">
                      <p class="text-sm font-semibold text-romanSilver">
                        {{ option.title }}:
                      </p>
                    </div>
                    <div class="w-4/5 flex items-start">
                      <p class="text-sm font-semibold">
                        {{ option.value }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Card>

        <div class="w-full flex justify-center">
          <div class="w-4/5 border-2" />
        </div>

        <Card class="w-full py-3 px-4 rounded-md flex gap-5">
          <PaySlipEarning
            type="Monthly Earning"
            :earning="monthlyEarning"
            :earning-chart="monthlyEarningChart"
            :loading="loadingMonthly"
            :selected="earningMonthSelected"
            :options-box="monthlyOptions"
            @select="getSelected"
          />

          <PaySlipEarning
            type="Annual Earning"
            :earning="yearlyEarning"
            :earning-chart="yearlyEarningChart"
            :loading="loadingYearly"
            :selected="earningYearSelected"
            :options-box="yearlyOptions"
            @select="getSelected"
          />
        </Card>

        <div class="w-full flex flex-col gap-5">
          <p class="text-base font-semibold text-darkPurple">Payslips</p>

          <div class="w-full flex flex-col">
            <p class="font-semibold text-base my-2">Filter By</p>
            <div class="flex justify-between items-center">
              <div class="flex flex-grow">
                <radio-button
                  :options="optionBox"
                  col-span="col-span-6"
                  class="mt-4 text-darkPurple"
                  row-gap="gap-x-8"
                  space-between="mr-2"
                  v-model="filterCheck"
                />
              </div>
              <Card class="flex p-3 gap-3 justify-end">
                <date-range-picker
                  :range="paySlipFilter === 'range'"
                  :editable="true"
                  type="month"
                  v-model="selectedDate"
                  placeholder="Select Date"
                  @input="getDateRange($event)"
                />

                <div class="flex w-auto">
                  <Button
                    width="5rem"
                    class="bg-dynamicBackBtn text-white -mt-2"
                    @click="filterPaySlip()"
                  >
                    Apply
                  </Button>
                </div>
              </Card>
            </div>
          </div>
        </div>

        <div style="height: 100%" class="mt-2" v-if="loadingTable">
          <loader size="xxs" :loader-image="false" />
        </div>

        <div v-else>
          <sTable
            :headers="paySlipHeader"
            :items="paySlipData"
            v-if="paySlipData.length > 0"
            class="w-full mt-4 mb-6"
            :has-number="false"
            aria-label="requestTable"
            :loading="false"
          >
            <template v-slot:item="{ item }">
              <div v-if="item.year" class="underline pt-2 cursor-pointer">
                <span class="w-auto" @click="viewPaySlip(item.data.id)">
                  {{
                    $DATEFORMAT(
                      new Date(
                        item.data.year + "-" + "0" + item.data.month + "-" + 1
                      ),
                      "LLLL yyyy"
                    )
                  }}
                  Payslip
                </span>
              </div>
              <div v-else-if="item.payrun">
                {{
                  item.data.payrun.payFrequency
                    ? handlePayFrequency(item.data.payrun.payFrequency)
                    : "-"
                }}
              </div>
              <div v-else-if="item.documentId">
                {{
                  `${$DATEFORMAT(
                    new Date(item.data.payrun.month + "-" + 1),
                    "LLLL"
                  )} ${item.data.payrun.payStartDay} - ${
                    item.data.payrun.payEndDay
                  }`
                }}
              </div>
              <div class="flex w-4/6 gap-5 justify-between" v-else-if="item.id">
                <div
                  @click="viewPaySlip(item.data.id)"
                  class="flex text-blueCrayola py-1 more h-8 cursor-pointer"
                >
                  <icon
                    icon-name="icon-eye"
                    class-name="text-blueCrayola"
                    size="xs"
                  />
                  <p class="underline text-sm ml-3">View</p>
                </div>

                <div
                  v-if="hasDownloadStarted && downloadId === item.data.id"
                  class="ml-3"
                >
                  <loader
                    size="xxxs"
                    :loader-image="false"
                    loading-bar-size="width:80px; margin-top: 2px"
                  />
                </div>
                <div
                  @click="invokeDownloadPaySlip(item.data.id)"
                  class="flex justify-center items-center ml-3 text-aztecJade py-1 more h-8 cursor-pointer"
                  v-if="!hasDownloadStarted || downloadId !== item.data.id"
                >
                  <icon
                    icon-name="download-icon"
                    class-name="text-aztecJade"
                    size="xs"
                  />
                  <p class="underline text-sm ml-1">Download</p>
                </div>
              </div>
            </template>
          </sTable>

          <div v-if="paySlipData.length === 0" class="mt-4">
            <div class="flex font-bold text-desire text-base justify-center">
              You will find all payslips for this employee here, from the time
              you pay the employee using Cloudenly.
            </div>
          </div>
        </div>
      </div>
      <PrintDownloadPaySlip
        ref="print"
        @hasDownloadStarted="hasDownloadStarted = $event"
        v-if="paySlipData.length > 0"
      />
    </div>
  </div>
</template>

<script>
import DateRangePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import STable from "@scelloo/cloudenly-ui/src/components/table";
import RadioButton from "@/components/RadioButton";
import ErrorPage from "@/modules/Admin/error403";

export default {
  name: "PayAnalytics",
  components: {
    Card: () => import("@/components/Card"),
    Button: () => import("@/components/Button"),
    PaySlipEarning: () => import("@/components/PaySlipEarning"),
    PrintDownloadPaySlip: () => import("@/components/PrintDownloadPaySlip"),
    STable,
    RadioButton,
    DateRangePicker,
    ErrorPage,
  },
  props: {
    userDetails: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loadingTable: true,
      showError: false,
      loadingMonthly: true,
      loadingYearly: true,
      hasDownloadStarted: false,
      displayDownload: true,
      payDate: null,
      downloadId: null,
      selectedYear: null,
      selectedDate: null,
      filterCheck: "range",
      paySlipHeader: [
        { title: "PaySlip", value: "year", width: 25 },
        { title: "Pay Frequency", value: "payrun", width: 25 },
        { title: "Pay Period", value: "documentId", width: 25 },
        { title: "Actions", value: "id", width: 22 },
      ],
      paySlipData: [],
      optionYears: [],
      optionBox: [
        { name: "Specific Month", radioName: "month", value: "specific" },
        { name: "Period", radioName: "month", value: "range" },
      ],
      paySlipFilter: "range",
      optionMonth: [],
      start: {},
      end: {},
      grossPay: null,
      totalGrossPay: null,
      payPeriod: "",
      annualPayPeriod: "",
      monthlyChartData: {},
      annualChartData: {},
      monthlyLegend: [],
      annualLegend: [],
      monthlyEarnings: [],
      monthlyTimerId: null,
      monthlyEarningChart: {},
      earningMonthSelected: "",
      earningMonthOptions: [],
      yearlyEarnings: [],
      yearlyEarningChart: {},
      earningYearOptions: [],
      earningYearSelected: 0,
      yearlyTimerId: null,
      employeeOptions: [
        {
          title: "Function",
          value: this.userDetails.orgFunction?.name,
        },
        {
          title: "Designation",
          value: this.userDetails.userDesignation?.name,
        },
        {
          title: "Job Level",
          value: this.userDetails.userLevel?.name,
        },
        {
          title: "Office Location",
          value: this.userDetails.employeeOffice?.name,
        },
        {
          title: "Manager",
          value: this.userDetails.userReportingTo
            ? `${this.userDetails.userReportingTo.fname} ${this.userDetails.userReportingTo.lname}`
            : "---",
        },
        {
          title: "Length of Service",
          value: `${this.getLengthService()}`,
        },
      ],
    };
  },
  computed: {
    monthlyOptions() {
      return this.earningMonthOptions;
    },

    monthlyEarning() {
      return this.monthlyEarnings.find(
        (month) => month.payStartDate === this.earningMonthSelected
      );
    },

    yearlyOptions() {
      return this.earningYearOptions;
    },

    yearlyEarning() {
      return this.yearlyEarnings.find(
        (year) => year.year === this.earningYearSelected
      );
    },
  },
  watch: {
    hasDownloadStarted(newVal) {
      if (!newVal) {
        this.downloadId = "";
      }
    },
    monthlyEarning(newVal) {
      this.monthlyChart(newVal);
      this.monthlyTimerId = null;
    },
    yearlyEarning(newVal) {
      this.yearlyChart(newVal);
      this.yearlyTimerId = null;
    },
    filterCheck(data) {
      this.paySlipFilter = data;
    },
    payDate(data) {
      const month = data.split("-")[0];
      const year = data.split("-")[1];
      this.monthlyLegend = [];
      this.monthlyChartData = {};
      this.grossPay = null;
      this.payPeriod = null;
      this.getMonthly(month, year);
    },
  },
  methods: {
    monthlyChart(earning) {
      const monthly = {
        labels: [
          `Net Pay Amount`,
          `Additions`,
          `Deductions`,
          `Pension`,
          `PAYE`,
        ],
        datasets: [
          {
            backgroundColor: [
              "#F15A29",
              "#13B56A",
              "#490166",
              "#2176FF",
              "#490166",
            ],
            offset: 30,
            data: [
              Math.round(earning.netPay) || 0,
              Math.round(earning.additions) || 0,
              Math.round(earning.deductions) || 0,
              Math.round(earning.totalPension) || 0,
              Math.round(earning.paye) || 0,
            ],
          },
        ],
      };
      this.monthlyEarningChart = monthly;
      if (this.monthlyTimerId) {
        clearTimeout(this.monthlyTimerId);
      }
      this.monthlyTimerId = setTimeout(() => {
        this.loadingMonthly = false;
      }, 0);
    },

    getMonthlyPaySlipEarnings() {
      const { userId } = this.userDetails;
      this.$_getPaySlipMonthlyEarnings(userId).then((res) => {
        this.earningMonthSelected = res.data.payslipsData[0]?.payStartDate;
        this.monthlyEarnings = res.data.payslipsData;

        for (let i = 0; i < res.data.payslipsData.length; i++) {
          const setObjMonth = {
            id: res.data.payslipsData[i].payStartDate,
            name: res.data.payslipsData[i].payStartDate,
          };
          this.earningMonthOptions.push(setObjMonth);
        }
      });
    },

    getSelected(selected, type) {
      if (type === "Monthly Earning") {
        this.loadingMonthly = true;
        this.earningMonthSelected = selected;
      }
      if (type === "Annual Earning") {
        this.loadingYearly = true;
        this.earningYearSelected = +selected;
      }
    },

    yearlyChart(earning) {
      const yearly = {
        labels: [
          `Total Net Pay Amount`,
          `Total Additions`,
          `Total Deductions`,
          `Total Pension`,
          `Total PAYE`,
        ],
        datasets: [
          {
            backgroundColor: [
              "#F15A29",
              "#13B56A",
              "#490166",
              "#2176FF",
              "#13B56A",
            ],
            offset: 30,
            data: [
              earning.netPayPercentage || 0,
              earning.additionsPercentage || 0,
              earning.deductionsPercentage || 0,
              earning.totalPensionPercentage || 0,
              earning.payePercentage || 0,
            ],
          },
        ],
      };
      this.yearlyEarningChart = yearly;
      if (this.yearlyTimerId) {
        clearTimeout(this.yearlyTimerId);
      }
      this.yearlyTimerId = setTimeout(() => {
        this.loadingYearly = false;
      }, 0);
    },

    getYearlyPaySlipEarnings() {
      const { userId } = this.userDetails;
      this.$_getPaySlipYearlyEarnings(userId).then((res) => {
        this.earningYearSelected = res.data.data[0]?.year;
        this.yearlyEarnings = res.data.data;

        for (let i = 0; i < this.yearlyEarnings.length; i++) {
          const { year } = this.yearlyEarnings[i];
          const setObjYear = { id: year, name: `${year} (YTD)` };
          this.earningYearOptions.push(setObjYear);
        }
      });
    },

    async invokeDownloadPaySlip(slipId) {
      try {
        await this.$handlePrivilege("employeeDirectory", "downloadPayslipED");
        this.downloadId = slipId;
        this.hasDownloadStarted = true;
        this.$refs.print.downloadData(slipId);
      } catch (error) {
        return this.$toasted.error(
          "You do not have permission to perform this task",
          {
            duration: 5000,
          }
        );
      }
    },

    async viewPaySlip(payslipId) {
      try {
        await this.$handlePrivilege("employeeDirectory", "viewPayslipED");
        this.$router.push({
          name: "viewPaySlip",
          params: { id: payslipId },
        });
      } catch (error) {
        return this.$toasted.error(
          "You do not have permission to perform this task",
          {
            duration: 5000,
          }
        );
      }
    },

    getLengthService() {
      const today = new Date();

      if (this.userDetails.employmentDate) {
        const targetDate = new Date(this.userDetails.employmentDate);

        const differenceInMs = today - targetDate;

        const differenceInDays = differenceInMs / (1000 * 60 * 60 * 24);

        const years = Math.floor(differenceInDays / 365);
        const months = Math.floor((differenceInDays % 365) / 30);
        const days = Math.floor((differenceInDays % 365) % 30);

        if (years) {
          return `${years} Yr(s)`;
        }
        if (months) {
          return `${months} Months`;
        }
        if (days) {
          return `${days} days`;
        }
        return "No length of service";
      }
      return "---";
    },

    getDateRange(value) {
      const splitDate = value.toString().split(",");
      this.start = this.formatDate(splitDate[0]);
      this.end = this.formatDate(splitDate[1]);
      this.selectedDate = value;
    },

    formatDate(dateString) {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");

      const dateObj = { year: `${year}`, month: `${month}` };
      return dateObj;
    },

    filterPaySlip() {
      this.loadingTable = true;
      let params;

      if (this.paySlipFilter === "range") {
        params = `fromYear=${this.start.year}&fromMonth=${this.start.month}&toYear=${this.end.year}&toMonth=${this.end.month}`;
      } else {
        params = `fromYear=${this.start.year}&fromMonth=${this.start.month}&toYear=${this.start.year}&toMonth=${this.start.month}`;
      }

      this.$_filterPaySlips(this.userDetails.userId, params).then(
        (response) => {
          this.paySlipData = response.data.payslips;
          this.loadingTable = false;
        }
      );
    },
  },

  async mounted() {
    try {
      await this.$handlePrivilege("employeeDirectory", "viewPayAnalyticsED");
      let i;

      const employmentTime = new Date(this.userDetails.employmentDate);
      employmentTime.setHours(employmentTime.getHours() + 8);

      const employmentYear = employmentTime.getFullYear();
      const employmentMonth = employmentTime.getMonth() + 1;

      const currentDate = new Date();
      currentDate.setHours(currentDate.getHours() + 8);

      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth() + 1;

      for (i = employmentYear; i <= currentYear; i++) {
        const setObjYear = { id: i, name: i };
        this.optionYears.push(setObjYear);
      }

      this.selectedYear = currentYear;

      const params = `fromYear=${employmentYear}&fromMonth=${employmentMonth}&toYear=${currentYear}&toMonth=${currentMonth}`;

      this.$_filterPaySlips(this.userDetails.userId, params).then(
        (response) => {
          this.paySlipData = response.data.payslips;
          this.loadingTable = false;
        }
      );

      this.getMonthlyPaySlipEarnings();
      this.getYearlyPaySlipEarnings();

      this.showError = false;
    } catch (error) {
      this.showError = true;
    }
  },
};
</script>
