<template>
  <div class="w-10/12">
    <div class="flex flex-col">
      <div class="w-full">
        <Tab
          :tabs="tabs"
          border
          :active-tab="currentTab"
          @currentTab="currentTab = $event"
        />
      </div>
      <div class="mt-7 flex-grow">
        <ReportingLine
          v-if="currentTab === 'Reporting Line'"
          :user-details="employeeDetails"
        />
        <DirectReporting
          v-if="currentTab === 'Direct Report(s)'"
          :user-details="employeeDetails"
        />
        <ApprovalRoles
          v-if="currentTab === 'Approval Role(s)'"
          :user-details="employeeDetails"
        />
        <AdminRoles
          v-if="currentTab === 'Admin Role(s)'"
          :user-details="employeeDetails"
        />
        <LeaveHistory
          v-if="currentTab === 'Leave History'"
          :user-details="employeeDetails"
        />
        <Finance
          v-if="currentTab === 'Finance'"
          :user-details="employeeDetails"
        />
        <Timeline
          v-if="currentTab === 'Timeline'"
          :user-details="employeeDetails"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Tab from "@scelloo/cloudenly-ui/src/components/tab";
import ApprovalRoles from "./approvalRoles";
import AdminRoles from "./adminRoles";
import LeaveHistory from "./leaveHistory";
import Finance from "./Finance";
import Timeline from "./timeline";
import ReportingLine from "./ReportingLine";
import DirectReporting from "./DirectReporting";

export default {
  name: "EmployeeInsights",
  components: {
    Tab,
    ApprovalRoles,
    LeaveHistory,
    Finance,
    Timeline,
    AdminRoles,
    ReportingLine,
    DirectReporting,
  },
  data() {
    return {
      currentTab: "Reporting Line",
      tabs: [],
    };
  },
  props: {
    employeeDetails: {
      type: Object,
      default: () => {},
    },
  },
  created() {
    if (process.env.NODE_ENV === "development") {
      this.tabs = [
        "Reporting Line",
        "Direct Report(s)",
        "Admin Role(s)",
        "Approval Role(s)",
        "Leave History",
        "Finance",
        "Timeline",
      ];
    } else {
      this.tabs = [
        "Reporting Line",
        "Direct Report(s)",
        "Admin Role(s)",
        "Approval Role(s)",
        "Leave History",
        "Timeline",
      ];
    }
  },
};
</script>
