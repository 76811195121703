<template>
  <div>
    <div class="w-full">
      <p class="font-semibold mb-4 inline-block">Work Hours</p>
      <span
        v-if="!cannotEdit"
        class="float-right cursor-pointer text-flame text-base font-semibold"
      >
        <span v-if="!edit" @click="handleClick">
          <icon
            icon-name="icon-edit"
            size="xs"
            class="cursor-pointer inline-block"
          />
          <span class="pl-2">Edit</span>
        </span>
        <span v-else @click="saveWorkHours">
          <icon
            icon-name="save"
            size="xs"
            class="cursor-pointer inline-block"
          />
          <span class="pl-2">Save</span>
        </span>
      </span>
      <div class="w-full flex justify-end" v-if="edit">
        <Button
          background-color="none"
          class="text-dynamicBackBtn border border-solid border-dynamicBackBtn mt-2 mb-4 ml-4"
          @click="openSettingsModal = true"
        >
          <span class="px-2">Settings Preferences</span>
        </Button>
      </div>
    </div>

    <div
      class="grid grid-cols-12 gap-6 text-darkPurple"
      style="margin-top: 1.125rem"
    >
      <div class="col-span-4 flex">
        <c-text
          placeholder="--Enter--"
          label="Work Hours"
          class="w-36"
          type="number"
          v-model="hoursPerDay"
          :disabled="!edit"
        />
        <p class="mt-8 ml-2">Hours Per Day</p>
      </div>
      <div class="col-span-4 flex">
        <c-text
          placeholder="--Enter--"
          class="w-36"
          label="Work Days"
          v-model="daysPerWeek"
          :disabled="!edit"
        />
        <p class="mt-8 ml-2">Days Per Week</p>
      </div>
      <div class="col-span-4 flex">
        <c-text
          placeholder="--Enter--"
          class="w-36"
          label="Work Weeks"
          v-model="weeksPerAnnum"
          :disabled="!edit"
        />
        <p class="mt-8 ml-2">Weeks Per Year</p>
      </div>
      <div class="col-span-4">
        <div class="text-sm mb-2 text-jet">Total Work Hours</div>
        <div class="payInfo payInfo_disabled text-center">
          {{ totalWorkHours }}
        </div>
      </div>
      <div class="col-span-4">
        <div class="text-sm mb-2 text-jet">Hourly Work Rate(₦)</div>
        <div class="payInfo payInfo_disabled text-center">
          {{ hourlyWorkRate }}
        </div>
      </div>
      <div class="col-span-4">
        <div class="text-sm mb-2 text-jet">Daily Work Rate(₦)</div>
        <div class="payInfo payInfo_disabled text-center">
          {{ dailyWorkRate }}
        </div>
      </div>
    </div>

    <RightSideBar
      v-if="openSettingsModal"
      @submit="saveSettings"
      submit="Save"
      @close="openSettingsModal = false"
      button-class="bg-dynamicBackBtn text-white"
    >
      <template v-slot:title>
        <p class="text-darkPurple">Settings Preferences</p>
      </template>
      <div class="mb-8 -mt-8">
        <alert
          message="Let us know how you would prefer to treat this employee’s work hours settings."
          variant="primary"
          :time="2"
          style="
            position: relative;
            right: 0;
            top: 35px;
            border: 1px solid #e99323;
            box-shadow: none;
            background: rgba(244, 213, 176, 0.15);
            color: black;
          "
        />
      </div>
      <div class="mt-4">
        <label class="text-romanSilver font-bold text-xs">Work hours </label>
        <checkbox
          v-model="defaultPaySchedule"
          label="Allow changes in general settings to override employee work hours settings"
          class="mt-2"
          label-style=" margin-left: 8px"
          checkbox-size="width:16px; height:16px; margin: 2px 4px 0 0;"
        />
      </div>
    </RightSideBar>
  </div>
</template>

<script>
import { mapState } from "vuex";
import CText from "@scelloo/cloudenly-ui/src/components/text";
import Alert from "@scelloo/cloudenly-ui/src/components/alert";
import Checkbox from "@scelloo/cloudenly-ui/src/components/checkbox";
import Button from "@/components/Button";
import RightSideBar from "@/components/RightSideBar";

export default {
  components: { CText, Button, RightSideBar, Alert, Checkbox },
  props: {
    cannotEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      edit: false,
      daysPerWeek: null,
      weeksPerAnnum: "52",
      hoursPerDay: null,
      totalWorkHours: "0",
      hourlyWorkRate: "0",
      dailyWorkRate: "0",
      openSettingsModal: false,
      inheritGeneralPayeSetting: false,
      inheritGeneralPensionSetting: false,
      inheritGeneralNHFSetting: false,
      defaultPaySchedule: false,
    };
  },
  computed: {
    ...mapState({
      employeeDetails: (state) => state.employee.allEmployeeDetails,
    }),
  },
  watch: {
    daysPerWeek() {
      return this.checkDateCalculation();
    },
    hoursPerDay() {
      this.checkDateCalculation();
    },
    weeksPerAnnum() {
      return this.checkDateCalculation();
    },
  },
  methods: {
    async handleClick() {
      try {
        await this.$handlePrivilege("employeeDirectory", "editEmployeePayInfo");
        this.edit = !this.edit;
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000,
        });
      }
    },
    handleModalClose() {
      this.$store
        .dispatch("employee/getOneEmployeeAll", this.$route.params.id)
        .then(() => {
          this.getDetails();
        });
    },
    async getDetails() {
      this.loadingPage = true;
      console.log(this.employeeDetails);
    },
    saveSettings() {
      const payload = {
        inheritGeneralPensionSetting: this.inheritGeneralPensionSetting,
        inheritGeneralPayeSetting: this.inheritGeneralPayeSetting,
        inheritGeneralNHFSetting: this.inheritGeneralNHFSetting,
        defaultPaySchedule: this.defaultPaySchedule,
      };
      this.$_saveSettingPreference(this.$route.params.id, payload)
        .then(() => {
          this.openSettingsModal = false;
          this.handleModalClose();
          this.$toasted.success("Settings saved successfully", {
            duration: 5000,
          });
        })
        .catch(() => {
          this.openSettingsModal = false;
          this.$toasted.error(
            "Settings not saved please contact your administrator",
            { duration: 5000 }
          );
        });
    },
    saveWorkHours() {
      const workHours = {};
      workHours.daysPerWeek = this.daysPerWeek;
      workHours.weeksPerAnnum = this.weeksPerAnnum;
      workHours.hoursPerDay = this.hoursPerDay;
      workHours.containsEmploymentInfo = true;
      this.$_editEmployeeByType({
        id: this.$route.params.id,
        type: "workhours",
        payload: workHours,
      }).then(() => {
        this.$store.dispatch(
          "employee/getOneEmployeeAll",
          this.$route.params.id
        );
        this.edit = !this.edit;
      });
    },
    checkDateCalculation() {
      const amount = parseFloat(this.employeeDetails.grossPay);
      if (this.daysPerWeek && this.hoursPerDay && this.weeksPerAnnum) {
        const hourPerDay = Number(this.hoursPerDay);
        const daysPerWeek = Number(this.daysPerWeek);
        const weeksPerAnnum = Number(this.weeksPerAnnum);
        const hourRate = amount / (hourPerDay * daysPerWeek * weeksPerAnnum);

        const dailyRate = amount / (daysPerWeek * weeksPerAnnum);
        this.hourlyWorkRate = hourRate ? hourRate.toLocaleString() : 0;
        this.dailyWorkRate = dailyRate ? dailyRate.toLocaleString() : 0;
        this.totalWorkHours = Number(hourPerDay * daysPerWeek * weeksPerAnnum);
      }
    },
  },
  async mounted() {
    const { daysPerWeek, weeksPerAnnum, hoursPerDay } = await this
      .employeeDetails;
    this.daysPerWeek = daysPerWeek.toString();
    this.weeksPerAnnum = weeksPerAnnum.toString();
    this.hoursPerDay = hoursPerDay.toString();
    this.inheritGeneralPayeSetting =
      this.employeeDetails.inheritGeneralPayeSetting;
    this.inheritGeneralPensionSetting =
      this.employeeDetails.inheritGeneralPensionSetting;
    this.inheritGeneralNHFSetting =
      this.employeeDetails.inheritGeneralNHFSetting;
    this.defaultPaySchedule = this.employeeDetails.defaultPaySchedule;
    this.checkDateCalculation();
  },
};
</script>

<style lang="scss">
.payInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  &_fakeInput {
    background: #f7f7ff;
    border-color: #878e99;
    padding: 10px 15px;
    max-height: 40px;
  }
  &_disabled {
    padding: 10px 15px;
    min-height: 40px;
    background: #eeeeee;
    border-color: #878e99;
  }
  &_unallocated {
    padding: 12px 16px;
    background: #fff5f5;
    border-color: #ea3c53;
    max-height: 65px;
  }
}
</style>
