<template>
  <div class="w-10/12">
    <div class="flex flex-col">
      <div class="w-full">
        <Tab :tabs="tabs" border :active-tab="currentTab" @currentTab="currentTab = $event" />
      </div>
      <div style="height: 100%" v-if="loading">
        <loader size="xxs" :loader-image="false" />
      </div>
      <div v-else class="mt-7 flex-grow">
        <annual-gross v-if="currentTab === 'Salary Breakdown'" :cannot-edit="cannotEdit" :revise-salary="reviseSalary"
          :total-gross-earning="totalGrossEarning" @grossPay="getPaysettings()" />
        <other-benefits v-if="currentTab === 'Other Benefits'" :other-benefit-data="otherBenefitData"
          :cannot-edit="cannotEdit" @submit="getPaysettings()" />
        <work-hours v-if="currentTab === 'Work Hours'" :cannot-edit="cannotEdit" />
        <account-info v-if="currentTab === 'Account Information'" :cannot-edit="cannotEdit" />
        <pay-analytics v-if="currentTab === 'Pay Analytics'" :user-details="employeeDetails" />
      </div>
      <divider class="my-8" />
      <div class="flex mb-5">
        <div class="flex mt-4 flex-grow"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Tab from "@scelloo/cloudenly-ui/src/components/tab";
import { mapState } from "vuex";

import Divider from "@/components/divider";
import AccountInfo from "./accountInfo";
import OtherBenefits from "./otherBenefits";
import AnnualGross from "./annualGross";
import WorkHours from "./workHours";
import PayAnalytics from "./payAnalytics";

export default {
  name: "PayInfo",
  components: {
    Divider,
    Tab,
    AnnualGross,
    AccountInfo,
    OtherBenefits,
    WorkHours,
    PayAnalytics,
  },
  props: {
    cannotEdit: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      states: [],
      loading: true,
      currentTab: "Salary Breakdown",
      grossEarningsData: [],
      annualGrossPay: "",
      totalGrossEarning: 0,
      otherBenefitData: [],
      reviseSalary: {},
      tabs: [],
    };
  },
  methods: {
    getPaysettings() {
      this.$_getEmployeePaySetting({
        employeeId: this.employeeDetails.id,
      }).then((result) => {
        const {
          annualGrossEarnings,
          otherEarnings,
          grossEarningsTotalAmount,
          otherEarningsTotalAmount,
          totalGross,
          reviseSalary,
        } = result.data;
        this.grossEarningsData = annualGrossEarnings;
        this.otherBenefitData = otherEarnings;
        this.totalOtherEarning = otherEarningsTotalAmount;
        this.totalGrossEarning = { grossEarningsTotalAmount, totalGross };
        this.reviseSalary = reviseSalary;
        this.loading = false;
      });
    },
  },
  computed: {
    ...mapState({
      employeeDetails: (state) => state.employee.allEmployeeDetails,
    }),
  },

  async mounted() {
    await this.getPaysettings();
    this.$store.dispatch("employee/getOneEmployeeAll", this.$route.params.id);
  },

  created() {
    this.tabs = [
      "Salary Breakdown",
      "Other Benefits",
      "Work Hours",
      "Account Information",
      "Pay Analytics",
    ];
  },
};
</script>

<style lang="scss" scoped>
.payInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;

  &_fakeInput {
    background: #f7f7ff;
    border-color: #878e99;
    padding: 10px 15px;
    max-height: 40px;
  }

  &_disabled {
    padding: 10px 15px;
    min-height: 40px;
    background: #eeeeee;
    border-color: #878e99;
  }

  &_unallocated {
    padding: 12px 16px;
    background: #fff5f5;
    border-color: #ea3c53;
    max-height: 65px;
  }
}
</style>
