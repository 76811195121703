<template>
  <div class="w-full flex flex-col gap-10">
    <div class="bg-ghostWhite w-full p-3 rounded-md">
      <div class="w-full flex bg-white gap-10 p-3 rounded-md">
        <div class="flex flex-col w-1/2 gap-3">
          <span class="flex justify-between items-center gap-2">
            <p class="text-base font-semibold">Employee Name:</p>
            <p class="text-base text-romanSilver">{{ userDetails.fname }} {{ userDetails.lname }}</p>
          </span>
          <span class="flex justify-between items-center gap-2">
            <p class="text-base font-semibold">Function:</p>
            <p class="text-base text-romanSilver">{{ userDetails.orgFunction?.name }}</p>
          </span>
        </div>

        <div class="flex flex-col w-1/2 gap-3">
          <span class="flex justify-between items-center gap-2">
            <p class="text-base font-semibold">Job Title:</p>
            <p class="text-base text-romanSilver">{{ userDetails.userDesignation?.name }}</p>
          </span>
          <span class="flex justify-between items-center gap-2">
            <p class="text-base font-semibold">Effective Terminal Date:</p>
            <p class="text-base text-romanSilver">{{ '---' }}</p>
          </span>
        </div>
      </div>
    </div>

    <div style="height: 100%" class="mt-28 pt-10" v-if="loading">
      <loader size="xxs" :loader-image="false" />
    </div>

    <div class="w-full gap-5 mb-6 flex" v-else>
      <Card class="w-full py-3 px-4 flex flex-col gap-5">
        <div class="flex justify-between p-3 gap-5 bg-ghostWhite">
          <div class="flex w-full gap-3">
            <div class="p-2 h-10 rounded-md flex">
              <Icon size="s" icon-name="pay-folders" />
            </div>
            <div class="flex flex-col">
              <span class="text-base font-semibold text-blueCrayola"
                >Current Month’s Earning (P)</span
              >
              <span class="text-lg font-extrabold">---</span>
            </div>
          </div>
          <div class="border-2 border-dashed bg-white w-0" />
          <div class="flex w-full gap-3">
            <div class="p-2 h-10 rounded-md flex">
              <Icon size="s" icon-name="pay-folders-sec" class="text-desire" />
            </div>
            <div class="flex flex-col">
              <span class="text-base font-semibold text-desire"
                >Total Regular Earning</span
              >
              <span class="text-lg font-extrabold">---</span>
            </div>
          </div>
          <div class="border-2 border-dashed bg-white w-0" />
          <div class="flex w-full gap-3">
            <div class="p-2 h-10 rounded-md flex">
              <Icon
                size="s"
                icon-name="pay-folders-sec"
                class="text-carrotOrange"
              />
            </div>
            <div class="flex flex-col">
              <span class="text-base font-semibold text-carrotOrange"
                >Total Variable Earning</span
              >
              <span class="text-lg font-extrabold">---</span>
            </div>
          </div>
        </div>

        <div class="flex justify-between p-3 gap-5 bg-ghostWhite">
          <div class="flex w-full gap-3">
            <div class="p-2 h-10 rounded-md flex">
              <Icon
                size="s"
                icon-name="pay-folders-sec"
                class="text-mediumSeaGreen"
              />
            </div>
            <div class="flex flex-col">
              <span class="text-base font-semibold text-mediumSeaGreen"
                >Terminal Benefits</span
              >
              <span class="text-lg font-extrabold">---</span>
            </div>
          </div>
          <div class="border-2 border-dashed bg-white w-0" />
          <div class="flex w-full gap-3">
            <div class="p-2 h-10 rounded-md flex">
              <Icon
                size="s"
                icon-name="pay-folders-sec"
                class="text-chartPurple"
              />
            </div>
            <div class="flex flex-col">
              <span class="text-base font-semibold text-chartPurple"
                >Indebtedness</span
              >
              <span class="text-lg font-extrabold">---</span>
            </div>
          </div>
          <div class="border-2 border-dashed bg-white w-0" />
          <div class="flex w-full gap-3">
            <div class="p-2 h-10 rounded-md flex">
              <Icon size="s" icon-name="pay-folders-sec" class="text-flame" />
            </div>
            <div class="flex flex-col">
              <span class="text-base font-semibold text-flame"
                >Net Terminal Pay/Net Refund</span
              >
              <span class="text-lg font-extrabold">---</span>
            </div>
          </div>
        </div>
      </Card>
    </div>
  </div>
</template>

<script>
import Card from "@/components/Card";

export default {
  name: "Finance",
  components: { Card },
  data() {
    return {
      loading: true,
      financeAnalytics: {},
    }
  },
  props: {
    userDetails: {
      type: Object,
      default: () => {},
    },
  },
  mounted() {
    this.$_getEmployeeFinanceInsights(this.userDetails.userId).then((response) => {
      this.loading = false;
      this.financeAnalytics = response.data.employees.annualEarning;
    })
  },
};
</script>
