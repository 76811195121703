<template>
  <div>
    <div v-if="loading">
      <loader size="xxs" :loader-image="false" />
    </div>
    <div v-else-if="showError">
      <ErrorPage />
    </div>
      <div v-else>
        <org-chart class="-mt-6" v-if="ds && ds.children && ds.children.length > 0" :datasource="ds">
          <template slot-scope="{ nodeData }">
            <div class="w-full flex gap-3">
              <div class="flex">
                <img class="tw-mx-auto tw-rounded-full" style="height: 30px; width: 30px" :src="nodeData.photo"
                  alt="account" v-if="nodeData.photo" />
                <div v-else class="w-8 h-8 rounded text-blueCrayola border text-center font-semibold pt-1">
                  {{ $getInitials(`${nodeData.name}`) }}
                </div>
              </div>
              <div class="flex flex-col gap-1">
                <div class="font-semibold flex text-base text-romanSilver">
                  {{ nodeData.name }}
                </div>
                <div class="text-xs text-darkPurple capitalize text-left flex flex-col gap-1">
                  <p class="font-semibold">
                    {{ nodeData.title }}
                  </p>
                </div>
              </div>
            </div>
            <div class="w-full flex justify-center mb-3" v-if="nodeData.users.length > 1">
              <div class="w-[48px] py-2 px-3 -bottom-2 bg-darkPurple rounded-md absolute">
                <p class="text-white text-xs font-semibold">
                  +{{ nodeData.users.length }}
                </p>
              </div>
            </div>
          </template>
        </org-chart>

        <div class="flex flex-col gap-1" v-else>
          <div class="flex w-full justify-center">
            <Icon icon-name="dashboard-error" class="-mt-1 iconSize" style="width: 250px; height: 250px" />
          </div>
          <p class="text-xl text-center font-bold">No Data Available</p>
          <p class="text-xs text-center text-romanSilver">
            There is no data to show currently.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>



<script>
import ErrorPage from "@/modules/Admin/error403";
import Icon from "@/components/Icon";
import OrgChart from "./OrganizationChartContainer";

export default {
  name: "DirectReporting",
  components: { OrgChart, ErrorPage, Icon },
  data() {
    return {
      ds: null,
      loading: true,
      showError: false,
    };
  },
  props: {
    userDetails: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    transformData(node) {
      if (!node) return null;
      const transformedNode = {
        id: node.id,
        name: `${node.fname} ${node.lname}`,
        title: `Level ${node.level?.name}`,
        photo: node.photo || null,
        users: node.otherDesignationUser,
        children: node.reportees.length > 0 ? node.reportees.map((reportee) => this.transformData(reportee)) : [],
      };
      return transformedNode;
    },
  },
  async mounted() {
    try {
      await this.$handlePrivilege("employeeDirectory", "viewDirectReportED");
      const response = await this.$_getEmployeeDirectReportInsights(this.userDetails.userId);
      this.ds = this.transformData(response.data.data);
    } catch (error) {
      this.showError = true;
    } finally {
      this.loading = false;
    }
  },
};
</script>
