<template>
  <div class="w-10/12">
    <div style="height: 100%" v-if="loading">
      <loader size="xxs" :loader-image="false" />
    </div>
    <div v-else class="mt-6">
      <div class="w-full">
        <p class="font-semibold mb-7 inline-block">Education & Certification</p>
        <span
          v-if="!cannotEdit"
          class="float-right cursor-pointer text-flame text-base font-semibold"
        >
          <span @click="handleClick" v-if="!edit">
            <icon icon-name="icon-edit" size="xs" class="inline-block" />
            <span class="pl-2">Edit</span>
          </span>
          <span class="flex gap-3" v-else>
            <span
              class="flex gap-1"
              :class="disable ? 'cursor-not-allowed' : 'cursor-pointer'"
              @click="!disable ? handleSave() : null"
            >
              <icon icon-name="save" size="xs" class="inline-block" />
              <span class="pl-2 flex flex-col h-full items-center">Save</span>
            </span>
            <span
              class="pl-2 flex flex-col h-full text-black items-center"
              @click="getQualifications()"
              >Cancel</span
            >
          </span>
        </span>
      </div>

      <div class="flex flex-col gap-2 mt-1 border p-4 rounded-lg">
        <ValidationObserver v-slot="{ handleSubmit }">
          <div class="flex mt-2">
            <span class="text-sm font-semibold flex flex-grow"
              >Educational Credentials</span
            >
            <span
              class="flex gap-1 cursor-pointer"
              v-if="edit"
              @click="handleSubmit(addCredentials)"
            >
              <icon
                icon-name="icon-plus"
                class-name="text-carrotOrange"
                size="xms"
              />
              <span class="text-sm text-flame font-semibold">Add another</span>
            </span>
          </div>
          <divider />
          <div
            class="grid text-darkPurple"
            style="margin-top: 1.125rem"
            v-for="(data, index) in educationalInfo"
            :key="index"
          >
            <div class="grid grid-cols-12 gap-6" v-if="edit">
              <div class="col-span-4">
                <c-text
                  placeholder="--Enter--"
                  variant="w-full"
                  label="Qualification"
                  :rules="['required']"
                  v-model="data.qualification"
                />
              </div>
              <div class="col-span-4">
                <c-text
                  placeholder="--Enter--"
                  variant="w-full"
                  label="Institution"
                  :rules="['required']"
                  v-model="data.institution"
                />
              </div>
              <div class="col-span-3">
                <c-text
                  placeholder="--Enter--"
                  variant="w-full h-10"
                  label="Year Of Award"
                  :rules="['required']"
                  v-model="data.yearOfAward"
                />
              </div>
              <div class="col-span-1 mt-5 flex items-center justify-end">
                <span
                  class="flex flex-col cursor-pointer justify-center my-4"
                  @click="deleteEducationalInfo(index, data)"
                >
                  <Icon
                    icon-name="icon-trash"
                    size="xs"
                    class-name="text-desire"
                  />
                </span>
              </div>
            </div>

            <div class="grid grid-cols-12 gap-6" v-else>
              <div class="col-span-4">
                <c-text
                  placeholder="--Enter--"
                  variant="w-full"
                  label="Qualification"
                  :disabled="!edit"
                  v-model="data.qualification"
                  v-tooltip.top="`${data.qualification}`"
                />
              </div>
              <div class="col-span-4">
                <c-text
                  placeholder="--Enter--"
                  variant="w-full"
                  label="Institution"
                  :disabled="!edit"
                  v-model="data.institution"
                  v-tooltip.top="`${data.institution}`"
                />
              </div>
              <div class="col-span-4">
                <c-text
                  placeholder="--Enter--"
                  variant="w-full h-10"
                  label="Year Of Award"
                  :disabled="true"
                  v-model="data.yearOfAward"
                  v-tooltip.top="`${data.yearOfAward}`"
                />
              </div>
            </div>
          </div>
        </ValidationObserver>
      </div>

      <divider class="my-5" />

      <div class="flex flex-col gap-2 border p-4 rounded-lg">
        <ValidationObserver v-slot="{ handleSubmit }">
          <div class="flex mt-2">
            <span class="text-sm font-semibold flex flex-grow"
              >Professional Certifications</span
            >
            <span
              class="flex gap-1 cursor-pointer"
              v-if="edit"
              @click="handleSubmit(addCertifications)"
            >
              <icon
                icon-name="icon-plus"
                class-name="text-carrotOrange"
                size="xms"
              />
              <span class="text-sm text-flame font-semibold">Add another</span>
            </span>
          </div>
          <divider />
          <div
            class="grid text-darkPurple"
            style="margin-top: 1.125rem"
            v-for="(data, index) in certificationInfo"
            :key="index"
          >
            <div class="grid grid-cols-12 gap-6" v-if="edit">
              <div class="col-span-4">
                <c-text
                  placeholder="--Enter--"
                  variant="w-full"
                  label="Qualifications"
                  :rules="['required']"
                  v-model="data.qualification"
                />
              </div>
              <div class="col-span-4">
                <c-text
                  placeholder="--Enter--"
                  variant="w-full"
                  label="Institution"
                  :rules="['required']"
                  v-model="data.institution"
                />
              </div>
              <div class="col-span-3">
                <c-text
                  placeholder="--Enter--"
                  variant="w-full h-10"
                  label="Year Of Award"
                  :rules="['required']"
                  v-model="data.yearOfAward"
                />
              </div>
              <div class="col-span-1 mt-5 flex items-center justify-end">
                <span
                  class="flex flex-col cursor-pointer justify-center my-4"
                  @click="deleteCertificationInfo(index, data)"
                >
                  <Icon
                    icon-name="icon-trash"
                    size="xs"
                    class-name="text-desire"
                  />
                </span>
              </div>
            </div>

            <div class="grid grid-cols-12 gap-6" v-else>
              <div class="col-span-4">
                <c-text
                  placeholder="--Enter--"
                  variant="w-full"
                  label="Qualification"
                  :disabled="!edit"
                  v-model="data.qualification"
                  v-tooltip.top="`${data.qualification}`"
                />
              </div>
              <div class="col-span-4">
                <c-text
                  placeholder="--Enter--"
                  variant="w-full"
                  label="Institution"
                  :disabled="!edit"
                  v-model="data.institution"
                  v-tooltip.top="`${data.institution}`"
                />
              </div>
              <div class="col-span-4">
                <c-text
                  placeholder="--Enter--"
                  variant="w-full h-10"
                  label="Year Of Award"
                  :disabled="true"
                  v-model="data.yearOfAward"
                  v-tooltip.top="`${data.yearOfAward}`"
                />
              </div>
            </div>
          </div>
        </ValidationObserver>
      </div>
    </div>

    <Modal v-if="dialogDel">
      <Card class="p-5 flex flex-col max-w-md">
        <div class>
          <p class="font-bold ml-4 my-5 text-center">
            Are you sure you want to delete this Record?
          </p>
          <div class="flex">
            <Button
              class="text-white"
              @click="deleteQualification(dataId)"
              :background="appearance.buttonColor || defaultBTNColor"
            >
              Yes
            </Button>
            <Button
              background-color="none"
              class="text-dynamicBackBtn border border-solid border-dynamicBackBtn ml-4"
              @click="dialogDel = false"
            >
              Cancel
            </Button>
          </div>
        </div>
      </Card>
    </Modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { ValidationObserver, extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import CText from "@scelloo/cloudenly-ui/src/components/text";
import Divider from "@/components/divider";
import Button from "@/components/Button";
import Modal from "@/components/Modal";
import Card from "@/components/Card";

extend("required", required);

export default {
  name: "EmployeeCertificate",
  components: {
    ValidationObserver,
    CText,
    Divider,
    Modal,
    Card,
    Button,
  },
  props: {
    cannotEdit: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState({
      defaultBTNColor: (state) => state.orgAppearance.defaultBtnColor,
      appearance: (state) => state.orgAppearance.orgAppearanceData,
    }),

    shouldDisable() {
      const educationalDataFull = this.checkIsFull(this.educationalInfo);
      const educationalDataEmpty = this.checkIsEmpty(this.educationalInfo);
      const certificationDataFull = this.checkIsFull(this.certificationInfo);
      const certificationDataEmpty = this.checkIsEmpty(this.certificationInfo);

      // Condition: disable is false if either condition is met
      const condition1 =
        educationalDataFull &&
        (certificationDataEmpty || certificationDataFull);
      const condition2 =
        certificationDataFull && (educationalDataEmpty || educationalDataFull);

      return !(condition1 || condition2); // Disable is false if either condition is true
    },
  },

  data() {
    return {
      loading: true,
      dialogDel: false,
      edit: false,
      disable: true,
      educationalInfo: [],
      certificationInfo: [],
      dataId: "",
    };
  },

  watch: {
    shouldDisable(newValue) {
      this.disable = newValue;
    },
  },

  methods: {
    handleClick() {
      this.edit = !this.edit;
    },

    deleteEducationalInfo(position, data) {
      if (!data.id) {
        this.educationalInfo.splice(position, 1);
      } else {
        this.dataId = data.id;
        this.dialogDel = true;
      }
    },

    deleteCertificationInfo(position, data) {
      if (Object.values(data).every((value) => !value)) {
        this.certificationInfo.splice(position, 1);
      } else {
        this.dataId = data.id;
        this.dialogDel = true;
      }
    },

    handleSave() {
      const certificationInfoCheck = this.checkIsEmpty(this.certificationInfo);
      const educationalInfoCheck = this.checkIsEmpty(this.educationalInfo);

      const payload = {
        educationalInfo: !educationalInfoCheck
          ? this.educationalInfo.map(({ id, ...rest }) => rest)
          : null,
        certificationInfo: !certificationInfoCheck
          ? this.certificationInfo.map(({ id, ...rest }) => rest)
          : null,
      };

      this.$_createEmployeeCertifications(payload, this.$route.params.id)
        .then((response) => {
          this.$toasted.success(response.data.message, { duration: 3000 });
          this.edit = false;
        })
        .catch((error) => {
          this.$toasted.error(error.message, { duration: 3000 });
        });
    },

    checkIsEmpty(arrData) {
      return arrData.every((obj) =>
        Object.keys(obj).every((key) => obj[key] === "")
      );
    },

    checkIsFull(arrData) {
      return arrData.every((obj) =>
        Object.keys(obj).every((key) => obj[key] !== "")
      );
    },
    addCertifications() {
      this.certificationInfo.push({
        institution: "",
        qualification: "",
        yearOfAward: "",
      });
    },

    addCredentials() {
      this.educationalInfo.push({
        institution: "",
        qualification: "",
        yearOfAward: "",
      });
    },

    deleteQualification(id) {
      this.$_deleteEducationQualification(id, this.$route.params.id)
        .then((response) => {
          this.$toasted.success(response.data.message, { duration: 3000 });
          this.loading = true;
          this.dialogDel = false;
          this.getQualifications();
        })
        .catch((error) => {
          this.$toasted.error(error.message, { duration: 3000 });
        });
    },

    getQualifications() {
      this.loading = true;
      this.educationalInfo = [];
      this.certificationInfo = [];

      this.$_employeeCertifications(this.$route.params.id).then((response) => {
        if (response.data.certificationInfo.length === 0) {
          this.addCertifications();
        } else {
          this.certificationInfo = response.data.certificationInfo;
        }
        if (response.data.educationalInfo.length === 0) {
          this.addCredentials();
        } else {
          this.educationalInfo = response.data.educationalInfo;
        }
        this.loading = false;
        this.edit = false;
      });
    },
  },

  mounted() {
    this.getQualifications();
  },
};
</script>
